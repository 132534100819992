import React from 'react';
import './page-title.css';

const PageTitle = (props) => {
  const { title, subtitle } = props;
  return (
    <div className="page-title">
      <h1 className="page-title-header">{title}</h1>
      {subtitle && <h2 className="page-title-subtitle">{subtitle}</h2>}
    </div>
  );
};

PageTitle.propTypes = {};

PageTitle.defaultProps = {};

export default PageTitle;
