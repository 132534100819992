import React from 'react';
import PropTypes from 'prop-types';
import './counter.css';

const Counter = (props) => {
  const { value, title, dimension, className } = props;
  return (
    <div className={`dimension ${className}`} data-testid='Counter'>
      <h3 className='counter-title'>{title}</h3>
      <p className='counter-value'>{value}</p>
      { typeof dimension == 'string'? <p className='counter-dimension'>{dimension}</p> : null }
    </div>
  );
}

Counter.propTypes = {};

Counter.defaultProps = {};

export default Counter;
