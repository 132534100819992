import axios from 'axios';

const path = '/api';

function getReviews() {
  return axios.get(`${path}/reviews/`).then((response) =>
    response.data.map((o) => ({
      ...o,
      date: new Date(o.travel_date),
    }))
  );
}

function getEstablishments() {
  return axios.get(`${path}/establisments/`).then((response) => response.data);
}

async function getAllData() {
  try {
    // console.time('Fetching All Data Time');
    const [reviewsResponse, establishmentsResponse] = await Promise.all([
      getReviews(),
      getEstablishments(),
    ]);
    // console.timeEnd('Fetching All Data Time');

    return {
      reviews: reviewsResponse,
      establishments: establishmentsResponse,
    };
  } catch (error) {
    console.error('Error fetching all data:', error);
    throw error;
  }
}

const service = {
  getAllData,
};
export default service;
