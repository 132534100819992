import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';

import './bar-chart.css';

const HorizontalBarChart = (props) => {
  const { id, data, height, color } = props;

  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');

  const [width, setWidth] = useState(0);

  let margin = { top: 12, right: 20, bottom: 30, left: 120 },
    svgHeight = data.length * 25 - margin.bottom - margin.top; // Adjusted height dynamically based on data length

  function initChart() {
    let initialWidth = document.getElementById(`chart-${id}`).getBoundingClientRect().width - margin.left - margin.right;
    setWidth(initialWidth);

    let svg = d3.select(`#chart-${id}`)
      .append('svg')
      .attr('width', initialWidth + margin.left + margin.right)
      .attr('height', svgHeight + margin.top + margin.bottom)
      .append('g')
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    svg.append("g")
      .attr('class', 'y-axis');

    svg.append("g")
      .attr('class', 'x-axis')
      .attr("transform", `translate(0, ${svgHeight})`);
  }

  function updateChart() {
    let svg = d3.select(`#chart-${id} svg g`);

    let y = d3.scaleBand()
      .range([0, svgHeight])
      .domain(data.map(d => d.key))
      .padding(0.2);

    let x = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([0, width]);

    function mouseover(e, d) {
      svg.selectAll('rect').attr('fill-opacity', 0.6);
      this.setAttribute('fill-opacity', 1);
      let rects = svg.selectAll('rect').nodes(),
        index = rects.indexOf(this);
      svg.selectAll('.y-axis text').attr('fill-opacity', 0.6);
      svg.selectAll('.y-axis text')
        .filter((d, i) => i === index)
        .attr('fill-opacity', 1);
      setTooltipTitle(d.key);
      setTooltipValue(d.value);
      setTooltipVisible(true);
    }

    function mousemove(e) {
      setTooltipTop(e.clientY + 'px');
      setTooltipLeft(e.clientX + 'px');
    }

    function mouseleave() {
      svg.selectAll('rect').attr('fill-opacity', 1);
      svg.selectAll('.y-axis text').attr('fill-opacity', 1);
      setTooltipVisible(false);
    }

    svg.selectAll('.y-axis')
      .transition()
      .duration(1000)
      .call(d3.axisLeft(y));

    svg.selectAll('.x-axis')
      .transition()
      .duration(1000)
      .call(d3.axisBottom(x).ticks(5));

    svg.selectAll('.y-axis path, .y-axis line, .x-axis path, .x-axis line').remove();

    svg.selectAll(".tick text").attr('class', 'chart_tick_text');

    svg.selectAll(".mybar")
      .data(data)
      .join("rect")
      .attr('class', 'mybar')
      .attr("fill", color ? color : "#FDB022")  // Default color updated
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseleave)
      .transition()
      .duration(1000)
      .attr('rx', () => y.bandwidth() > 16 ? 8 : y.bandwidth() / 2)
      .attr("y", d => y(d.key))
      .attr("x", d => 0)
      .attr("width", d => x(d.value))
      .attr("height", () => y.bandwidth() > 16 ? 16 : y.bandwidth());
  }

  useEffect(() => {
    if (data && data.length > 0) {
      initChart();
      updateChart();
    }
  }, [data]);

  return (
    <div className='chart-container scrollable-chart-container' id={`chart-${id}`}>
      <ChartTooltip
        top={tooltipTop}
        left={tooltipLeft}
        title={tooltipTitle}
        visible={tooltipVisible}
        value={tooltipValue}
      />
    </div>
  );
}

HorizontalBarChart.propTypes = {};

HorizontalBarChart.defaultProps = {};

export default HorizontalBarChart;
