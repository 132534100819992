import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer, Select, Space, DatePicker } from 'antd';

import Context from '../../context/context';
import './filters.css';

import { PLATFORM_TYPES, ESTABLISHMENT_TYPES, SENTIMENT_TYPES, ASPECT_TYPES, REGION_TYPES } from '../../constants';

const { RangePicker } = DatePicker;

const FiltersContent = () => {
  const {
    selectedPlatform,
    selectedCategory,
    selectedSubCategory,
    selectedDates,
    selectedSentiment,
    activeSubCategories,
    selectedAspect,
    selectedRegion,
    updateSelectedPlatform,
    updateSelectedCategory,
    updateSelectedSubCategory,
    updateSelectedDates,
    updateSelectedSentiment,
    updateSelectedAspect,
    updateSelectedRegion,
    establishmentTypes,
  } = useContext(Context);

  const platformTypes = PLATFORM_TYPES.map(p => ({
    label: p,
    value: p,
  }));

  const sentimentTypes = [
    {
      label: 'All',
      value: null,
    },
    ...SENTIMENT_TYPES.map(p => ({
      label: p,
      value: p,
    }))
  ]

  const aspectTypes = ASPECT_TYPES.map(p => ({
    label: p,
    value: p,
  }));

  const subcategoryTypes = activeSubCategories.map(p => ({
    label: p,
    value: p,
  }));

  const dynamicEstablishmentTypes = [
    { label: 'All', value: null },
    ...establishmentTypes.map((type) => ({
      label: type,
      value: type,
    })),
  ];

  const regionTypes = REGION_TYPES.map(p => ({
    label: p,
    value: p,
  }));

  return (
    <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
      <div>
        <h5 className='filters-select-label'>Date range:</h5>
        <RangePicker
          value={selectedDates}
          onChange={updateSelectedDates}
          style={{width: '100%'}}
          variant='outlined'
        />
      </div>
      <div>
        <h5 className='filters-select-label'>Category:</h5>
        <Select
          style={{ width: '100%' }}
          value={selectedCategory}
          onChange={updateSelectedCategory}
          options={dynamicEstablishmentTypes} // Dynamic options
        />
      </div>
      <div>
        <h5 className='filters-select-label'>Subcategory:</h5>
        <Select
          style={{width: '100%'}}
          variant='outlined'
          mode="multiple"
          value={selectedSubCategory}
          onChange={updateSelectedSubCategory}
          options={subcategoryTypes}
        />
      </div>
      <div>
        <h5 className='filters-select-label'>Sentiment:</h5>
        <Select
          style={{width: '100%'}}
          variant='outlined'
          mode="single"
          value={selectedSentiment}
          onChange={updateSelectedSentiment}
          options={sentimentTypes}
        />
      </div>
      <div>
        <h5 className='filters-select-label'>Aspect:</h5>
        <Select
          style={{width: '100%'}}
          variant='outlined'
          mode="multiple"
          value={selectedAspect}
          onChange={updateSelectedAspect}
          options={aspectTypes}
        />
      </div>
      <div>
        <h5 className='filters-select-label'>Region:</h5>
        <Select
          style={{width: '100%'}}
          variant='outlined'
          mode="multiple"
          value={selectedRegion}
          onChange={updateSelectedRegion}
          options={regionTypes}
        />
      </div>
    </Space>
  );
}

const FiltersTitle = () => {
  const {
    updateSelectedPlatform,
    updateSelectedCategory,
    updateSelectedSubCategory,
    updateSelectedDates,
    updateSelectedSentiment,
    updateSelectedAspect,
    updateSelectedRegion,
  } = useContext(Context);

  function clearFilters() {
    updateSelectedPlatform(null);
    updateSelectedCategory(null);
    updateSelectedSubCategory([]);
    updateSelectedDates([]);
    updateSelectedSentiment(null);
    updateSelectedAspect([]);
    updateSelectedRegion([]);
  }
  return (
    <div className='filters-header'>
      <h1 className='filters-title'>Filters</h1>
      <button
        className='filters-clear-button'
        onClick={clearFilters}
      >
        Clear
      </button>
    </div>
  );
}

const Filters = (props) => {
  let location = useLocation();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return  location.pathname !== '/' ? (
    <>
      <button 
        className='button-main filters_button'
        onClick={() => handleOpenChange(true)}
      >
        <i className='filters_button_icon'>
          <img
            className='filters_button_icon-image'
            src={require('./../../assets/icons/filters.svg').default}
          />
        </i>
        Filters
      </button>
      <Drawer
        onClose={() => handleOpenChange(false)}
        title={<FiltersTitle />}
        open={open}
        closeIcon={false}
      >
        <FiltersContent />
      </Drawer>
    </>
  ) : ''
}

Filters.propTypes = {};

Filters.defaultProps = {};

export default Filters;
