import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'antd';
import Context from '../../context/context';
import { ASPECT_TYPES } from '../../constants';
import RadarChart from '../../components/radar/radar';
import Section from '../../components/section/section';
import ChartCard from '../../components/chart-card/chart-card';
import EstablishmentTypeToggle from '../../components/establishment-type-toggle/establishment-type-toggle';
import AppliedFilters from '../../components/applied-filters/applied-filters';
import PageTitle from '../../components/page-title/page-title';

import './aspects.css';

const Aspect = (props) => {
  const { aspect, value } = props;

  return (
    <div className="aspect">
      <div className="aspect-top-container">
        <div className="aspect-icon-container">
          <img
            className="aspect-icon"
            src={`/icons/${aspect.toLowerCase()}.png`}
            alt={aspect}
          />
        </div>
        <div className="aspect-name">{aspect}</div>
      </div>
      <div className="aspect-value">
        <div className="aspect-value-counter">{value}</div>
        Mentions
      </div>
    </div>
  );
};

const Aspects = () => {
  const {
    filteredReviews,
    selectedCategory,
    updateSelectedCategory,
    selectedPlatform,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
  } = useContext(Context);

  const [aspectsData, setAspectsData] = useState(computeAspectsData());
  const [radarData, setRadarData] = useState([]);

  function computeAspectsData() {
    const aspectsDataMap = {};

    ASPECT_TYPES.map((aspect) => {
      aspectsDataMap[aspect] = 0;
    });

    filteredReviews.forEach((review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        aspectsDataMap[aspect.aspect] += 1;
      });
    });
    return aspectsDataMap;
  }

  function computeRadarData() {
    const radarDataMap = {};

    ASPECT_TYPES.forEach((aspect) => {
      radarDataMap[aspect] = { positive: 0, negative: 0 };
    });

    filteredReviews.forEach((review) => {
      if (review.establishment_type === selectedCategory) {
        review.aspectsentiment_set.forEach((aspect) => {
          if (aspect.label === 'Positive') {
            radarDataMap[aspect.aspect].positive += 1;
          } else if (aspect.label === 'Negative') {
            radarDataMap[aspect.aspect].negative += 1;
          }
        });
      }
    });

    const computedData = Object.keys(radarDataMap).map((aspect) => {
      const positives = radarDataMap[aspect].positive;
      const negatives = radarDataMap[aspect].negative;
      const value =
        positives + negatives === 0
          ? 0
          : Math.round((positives / (positives + negatives)) * 100);

      return {
        aspect: aspect,
        value: value,
        positives: positives,
        negatives: negatives,
      };
    });

    console.log('Computed Radar Data:', computedData); // Log the data to the console

    return computedData;
  }
  function computeTotalAspects() {
    return Object.values(aspectsData).reduce((acc, value) => acc + value, 0);
  }

  useEffect(() => {
    setAspectsData(computeAspectsData());
  }, [filteredReviews]);

  useEffect(() => {
    if (selectedCategory === null) {
      updateSelectedCategory('Accommodation');
    } else {
      setRadarData(computeRadarData());
    }
  }, [filteredReviews, selectedCategory, updateSelectedCategory]);

  const appliedFilters = {
    platform: selectedPlatform,
    category: selectedCategory,
    subcategories: selectedSubCategory,
    sentiment: selectedSentiment,
    aspects: selectedAspect,
    regions: selectedRegion,
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <PageTitle title="Establishment Aspect Insights" />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AppliedFilters />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {ASPECT_TYPES.map((aspect) => {
          return (
            <Col key={aspect} span={6}>
              <Aspect aspect={aspect} value={aspectsData[aspect]} />
            </Col>
          );
        })}
        <Col span={6}>
          <Aspect aspect="Total" value={computeTotalAspects()} />
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
        <Col span={24}>
          <Section title={<>Aspect Performance Insights</>}>
            <EstablishmentTypeToggle />
            <ChartCard className="chart-card radar-chart">
              <RadarChart
                data={radarData}
                columns={{
                  aspect: 'Aspect',
                  value: 'Value',
                  positives: 'Positive sentiments',
                  negatives: 'Negative sentiments',
                }}
                fileName="establishment-aspect-performance"
                filters={appliedFilters}
                title={'Establishment Aspect Performance Insights'}
              />
            </ChartCard>
          </Section>
        </Col>
      </Row>
    </>
  );
};

Aspects.propTypes = {};

Aspects.defaultProps = {};

export default Aspects;
