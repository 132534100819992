import React from 'react';

import './section.css';

const Section = (props) => {
  const { title, children } = props;
  return (
    <div className='section'>
      {
        title && (
              <h2 className='section-header'>{title}</h2>
          )
      }
      { children }
    </div>
  )
}

Section.propTypes = {};

Section.defaultProps = {};

export default Section;
