import React, { useState, useEffect, useContext } from 'react';
import { Card, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import Counter from '../counter/counter.js';
import Context from '../../context/context';
import './platforms.css';

import { PLATFORM_TYPES } from '../../constants';

const Platform = (props) => {
  const { name, reviews, icon, active, clickHandler } = props;


  return (
    <div className='platform'>
      <i className={`platform_icon platform_icon_${icon}`}>
        <img
          className='platform_icon-image'
          src={require(`./../../assets/icons/${icon}.svg`)}      />
      </i>
      <button onClick={()=> {
        clickHandler({
          name:name,
          reviews:reviews,
          icon:icon,
        })
      }} className={`platform_button ${active ? 'platform_button-active' : '' }`}>
        <div>
          {name} ({reviews})
        </div>
        <ArrowRightOutlined className='platform_button_arrow' />
      </button>
    </div>
  )
}

const Platforms = (props) => {
  const { className } = props;

  const {
    noPlatformFilteredReviews,
    selectedPlatform,
    updateSelectedPlatform
  } = useContext(Context);

  function buildPlatformTypes (){
    return PLATFORM_TYPES.map(p => {
      return {
        name:p,
        reviews: noPlatformFilteredReviews.filter(r => r.data_platform === p).length,
        icon:p.toLowerCase()
      }
    })
  }

  const [platformReviews, setPlatformReviews] = useState(buildPlatformTypes());

  useEffect(()=>{setPlatformReviews(buildPlatformTypes())},[noPlatformFilteredReviews])

  function handleClick(e) {
    if(e.name === selectedPlatform) {
      return updateSelectedPlatform(null)
    }
    return updateSelectedPlatform(e.name)
  }

  return (
    <Card className={className} bordered={false}>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Counter
          title='# Data Platforms'
          value={PLATFORM_TYPES.length}
        />
        {
          platformReviews.map((platform) => {
            return (
              <Platform
                active={selectedPlatform === platform.name}
                key={platform.name}
                name={platform.name}
                reviews={platform.reviews}
                icon={platform.icon}
                clickHandler={handleClick}
              />
            )
          })
        }
      </Space>
    </Card>
  );
}

Platforms.propTypes = {};

Platforms.defaultProps = {};

export default Platforms;
