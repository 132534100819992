import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { downloadDataAsExcel } from '../../utils/download-as-excel';

const DownloadAsExcelButton = ({ data, columns, fileName }) => {
  const handleDownloadExcel = () => {
    const isObject = typeof data === 'object' && !Array.isArray(data);
    const formattedData = isObject
      ? Object.keys(data).map((key) => {
          const row = {};
          row.Region = key;
          Object.keys(columns).forEach((colKey) => {
            row[columns[colKey]] = data[key][colKey] || data[key];
          });
          return row;
        })
      : data.map((d) => {
          const row = {};
          Object.keys(columns).forEach((key) => {
            row[columns[key]] = d[key];
          });
          return row;
        });

    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    downloadDataAsExcel(`${fileName}_${timestamp}.xlsx`, formattedData);
  };

  return (
    <button onClick={handleDownloadExcel} className="download-btn">
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadAsExcelButton;

// Former implementation (when the data is an array)

// const DownloadAsExcelButton = ({ data, columns, fileName }) => {
//   const handleDownloadExcel = () => {
//     const formattedData = data.map((d) => {
//       const row = {};
//       Object.keys(columns).forEach((key) => {
//         row[columns[key]] = d[key];
//       });
//       return row;
//     });

//     const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
//     downloadDataAsExcel(`${fileName}_${timestamp}.xlsx`, formattedData);
//   };

//   return (
//     <button onClick={handleDownloadExcel} className="download-btn">
//       <FaDownload color="white" />
//     </button>
//   );
// };

// export default DownloadAsExcelButton;
