import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { links } from '../../constants';
import './navigation.css';

const Link = (props) => {
  const {text, link, icon} = props;

  return (
    <NavLink
      className={({ isActive, isPending }) =>
        `main-navigation-link${isActive ? " main-navigation-link-active" : ""}`
      }
      to={link}
    >
      <img
        className='main-navigation-link_icon'
        src={require(`./../../assets/icons/${icon}.svg`)}
        alt={text}
      />
      {text}
    </NavLink>
  )
};

const Navigation = () => {
  let location = useLocation();
  return location.pathname !== '/' ? (
    <nav className='main-navigation'>
      {
        links.map((link) => {
          return (
            <Link
              key={link.text}
              text={link.text}
              link={link.link}
              icon={link.icon}
            />
          )
        })
      }
    </nav>
  ) : '';
}


export default Navigation;
