import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import DownloadAsPNGButton from '../download-png-btn/download-as-png-btn.js';
import DownloadAsExcelButton from '../download-as-excel-btn/download-as-excel-btn.js';
import AppliedFilters from '../applied-filters/applied-filters.js';
import './radar.css';

const RadarChart = ({ data, columns, fileName, filters, title }) => {
  const chartRef = useRef();

  const chartId = `radar-chart-${Math.random().toString(36).substr(2, 9)}`;

  useEffect(() => {
    const margin = { top: 80, right: 100, bottom: 80, left: 100 };
    const containerWidth = chartRef.current.parentElement.offsetWidth;
    const width = Math.min(containerWidth, 900) - margin.left - margin.right;
    const height = width;
    const radius = Math.min(width / 2, height / 2);

    // Clear previous content
    d3.select(chartRef.current).selectAll('*').remove();

    const svg = d3.select(chartRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${width / 2 + margin.left}, ${height / 2 + margin.top})`);

    const angleSlice = (Math.PI * 2) / data.length;

    const rScale = d3.scaleLinear()
      .range([0, radius])
      .domain([0, 100]);

    // Tooltip
    const tooltip = d3.select('.radar-container')
      .append('div')
      .attr('class', 'radar-tooltip')
      .style('position', 'absolute')
      .style('background', '#333')
      .style('color', '#fff')
      .style('padding', '5px 10px')
      .style('border-radius', '4px')
      .style('opacity', 0)
      .style('pointer-events', 'none');

    // Circular grid
    svg.selectAll('.grid-circle')
      .data([20, 40, 60, 80, 100])
      .enter()
      .append('circle')
      .attr('class', 'grid-circle')
      .attr('r', d => rScale(d))
      .style('fill', '#CDCDCD')
      .style('stroke', '#CDCDCD')
      .style('fill-opacity', 0.1);

    const axisGrid = svg.append('g').attr('class', 'axis-wrapper');

    const axis = axisGrid.selectAll('.axis')
      .data(data)
      .enter()
      .append('g')
      .attr('class', 'axis');

    axis.append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', (d, i) => rScale(100) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr('y2', (d, i) => rScale(100) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr('class', 'line')
      .style('stroke', 'white')
      .style('stroke-width', '2px');

    axis.append('text')
      .attr('class', 'aspect-label')
      .attr('x', (d, i) => rScale(120) * Math.cos(angleSlice * i - Math.PI / 2))
      .attr('y', (d, i) => rScale(120) * Math.sin(angleSlice * i - Math.PI / 2))
      .attr('dy', '0.35em')
      .style('font-size', '18px')
      .style('text-anchor', 'middle')
      .style('fill', 'white')
      .text(d => d.aspect);

    const radarLine = d3.lineRadial()
      .radius(d => rScale(d.value))
      .angle((d, i) => i * angleSlice)
      .curve(d3.curveLinearClosed); // Ensure the line connects the first and last aspect

    // Area and line with hover functionality
    svg.append('path')
      .datum(data)
      .attr('class', 'radar-area')
      .attr('d', radarLine)
      .style('fill', '#2e90fa')
      .style('fill-opacity', 0.25)
      .on('mouseover', function() {
        tooltip.transition().duration(200).style('opacity', 0.9);
      })
      .on('mousemove', function(event) {
        const [x, y] = d3.pointer(event);
        const angle = (Math.atan2(y, x) + Math.PI / 2) % (2 * Math.PI);
        const i = Math.floor(angle / angleSlice);
        if (i >= 0 && i < data.length) {
          const d = data[i];
          tooltip
            .html(`
              <strong style="color:#4287f5">Aspect:</strong> ${d.aspect}<br>
              <strong style="color:#6fcf97"># of Positives:</strong> ${d.positives}<br>
              <strong style="color:#d05055"># of Negatives:</strong> ${d.negatives}<br>
              <strong style="color:#4287f5">Ratio:</strong> ${d.value}%
            `)
            .style('left', `${event.pageX - 300}px`)
            .style('top', `${event.pageY - 700}px`);
        }
      })
      .on('mouseout', function() {
        tooltip.transition().duration(500).style('opacity', 0);
      });

    svg.append('path')
      .datum(data)
      .attr('class', 'radar-line')
      .attr('d', radarLine)
      .style('stroke-width', '2px')
      .style('stroke', '#2e90fa')
      .style('fill', 'none');

    svg.append('text')
      .attr('class', 'radar-title')
      .attr('x', 0)
      .attr('y', -height / 2 - margin.top / 2)
      .attr('text-anchor', 'middle')
      .style('font-size', '25px');
  }, [data]);

  const formatFilters = (filters) => {
    const filterEntries = [];

    if (filters.category) {
      filterEntries.push(`Category: ${filters.category}`);
    }
    if (filters.platform) {
      filterEntries.push(`Platform: ${filters.platform}`);
    }
    if (filters.sentiment) {
      filterEntries.push(`Sentiment: ${filters.sentiment}`);
    }
    if (filters.subcategories.length > 0) {
      filterEntries.push(`Subcategories: ${filters.subcategories.join(', ')}`);
    }
    if (filters.regions.length > 0) {
      filterEntries.push(`Regions: ${filters.regions.join(', ')}`);
    }
    if (filters.aspects.length > 0) {
      filterEntries.push(`Aspects: ${filters.aspects.join(', ')}`);
    }

    return filterEntries.length > 0 ? filterEntries.join(' | ') : 'No filters applied';
  };

  return (
    <div className="chart-wrapper">
      <div className="radar-container" id={chartId}>
        <svg ref={chartRef}></svg>
      </div>
      <div className='download-btns'>
        <DownloadAsExcelButton
            data={data}
            columns={columns}
            fileName={fileName}
          />
        <DownloadAsPNGButton
          chartId={chartId}
          fileName="radar-chart.png"
          metadata={{
            title: title,
            filters: formatFilters(filters),
            downloadDate: new Date().toLocaleDateString(),
            websiteName: 'Mlondola AI for Tourism'
          }}
        />
      </div>
    </div>
  );
};

export default RadarChart;
