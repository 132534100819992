import { useReducer, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import Reducer from './context/reducer';
import Context from './context/context';

import router from './router';
import './App.css';

function App() {
  const initialState = {
    filteredReviews: [],
    filteredEstablishments: [],
    activeSubCategories: [],
    noPlatformFilteredReviews: [],
    selectedPlatform: null,
    selectedCategory: null,
    selectedSubCategory: [],
    selectedDates: [],
    selectedSentiment: null,
    selectedAspect: [],
    selectedRegion: [],
  };

  const [state, dispatch] = useReducer(Reducer, initialState);
  const [establishmentTypes, setEstablishmentTypes] = useState([]);

  const updateFilteredEstablishments = (filteredEstablishments) => {
    dispatch({
      type: 'UPDATE_FILTERED_ESTABLISHMENTS',
      payload: filteredEstablishments,
    });
  };

  const updateFilteredReviews = (filteredReviews) => {
    dispatch({
      type: 'UPDATE_FILTERED_REVIEWS',
      payload: filteredReviews,
    });
  };

  const updateActiveSubCategories = (activeSubCategories) => {
    dispatch({
      type: 'UPDATE_ACTIVE_SUB_CATEGORIES',
      payload: activeSubCategories,
    });
  };

  const updateSelectedCategory = (selectedCategory) => {
    dispatch({
      type: 'UPDATE_SELECTED_CATEGORY',
      payload: selectedCategory,
    });
  };

  const updateSelectedSubCategory = (selectedSubCategory) => {
    dispatch({
      type: 'UPDATE_SELECTED_SUB_CATEGORY',
      payload: selectedSubCategory,
    });
  };

  const updateSelectedDates = (selectedDates) => {
    dispatch({
      type: 'UPDATE_SELECTED_DATES',
      payload: selectedDates,
    });
  };

  const updateSelectedSentiment = (selectedSentiment) => {
    dispatch({
      type: 'UPDATE_SELECTED_SENTIMENT',
      payload: selectedSentiment,
    });
  };

  const updateSelectedAspect = (selectedAspect) => {
    dispatch({
      type: 'UPDATE_SELECTED_ASPECT',
      payload: selectedAspect,
    });
  };

  const updateSelectedRegion = (selectedRegion) => {
    dispatch({
      type: 'UPDATE_SELECTED_REGION',
      payload: selectedRegion,
    });
  };

  const updateNoPlatformFilteredReviews = (noPlatformFilteredReviews) => {
    dispatch({
      type: 'UPDATE_NO_PLATFORM_FILTERED_REVIEWS',
      payload: noPlatformFilteredReviews,
    });
  };

  const updateSelectedPlatform = (selectedPlatform) => {
    dispatch({
      type: 'UPDATE_SELECTED_PLATFORM',
      payload: selectedPlatform,
    });
  };

  return (
    <div className="App">
      <Context.Provider
        value={{
          ...state,
          updateSelectedPlatform,
          updateSelectedCategory,
          updateSelectedSubCategory,
          updateSelectedDates,
          updateSelectedSentiment,
          updateSelectedAspect,
          updateSelectedRegion,
          updateFilteredReviews,
          updateFilteredEstablishments,
          updateNoPlatformFilteredReviews,
          updateActiveSubCategories,
          establishmentTypes,
          setEstablishmentTypes,
        }}
      >
        <RouterProvider router={router} />
      </Context.Provider>
    </div>
  );
}

export default App;
