import React, { useContext } from 'react';
import Context from '../../context/context';

import { ESTABLISHMENT_TYPES } from '../../constants.js';
import './establishment-type-toggle.css';

const EstablishmentTypeToggle = () => {
  const { selectedCategory, updateSelectedCategory, establishmentTypes } =
    useContext(Context);

  return (
    <div className="establishment-type-toggle">
      {establishmentTypes.map((type, index) => (
        <button
          key={index}
          onClick={() => {
            selectedCategory === type
              ? updateSelectedCategory(null)
              : updateSelectedCategory(type);
          }}
          className={`establishment-type-toggle-button button-main ${
            selectedCategory === type ? 'button-main-inactive' : ''
          }`}
        >
          {type}
        </button>
      ))}
    </div>
  );
};

EstablishmentTypeToggle.propTypes = {};

EstablishmentTypeToggle.defaultProps = {};

export default EstablishmentTypeToggle;
