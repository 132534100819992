import React from 'react';
import { useLocation } from 'react-router-dom';
import { logout } from '../../utils/auth';
import { FiLogOut } from "react-icons/fi";

const LogoutButton = () => {
    let location = useLocation();
  return location.pathname !== '/' ? (
    <button
     onClick={logout}
      className="button-main"
      style={{ height: '38px' }}
      >
      <FiLogOut color="white" />
    </button>
  ) : '';
};

export default LogoutButton;