import { toPng } from 'html-to-image';
import './download-chart.css'

export const downloadChartAsPNG = async (chartId, fileName = 'chart.png', metadata = {}) => {
  const chartNode = document.getElementById(chartId);
  if (!chartNode) {
    console.error(`No element found with ID: ${chartId}`);
    return;
  }

  const { title = 'Chart', filters = 'No filters applied', downloadDate = new Date().toLocaleDateString(), websiteName = 'Website Name' } = metadata;
  const metadataContainer = document.createElement('div');
  metadataContainer.className = 'download-metadata';
  // metadataContainer.style.position = 'absolute';
  // metadataContainer.style.top = '10px';
  // metadataContainer.style.left = '50%';
  // metadataContainer.style.transform = 'translateX(-50%)';
  metadataContainer.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
  metadataContainer.style.padding = '10px';
  metadataContainer.style.borderRadius = '5px';
  metadataContainer.style.fontSize = '14px';
  metadataContainer.style.zIndex = '10';
  metadataContainer.style.textAlign = 'center'; 
  // metadataContainer.style.marginBottom = '20px';

  // Add metadata content
  const titleElement = document.createElement('p');
  titleElement.textContent = title;
  metadataContainer.appendChild(titleElement);

  const filtersElement = document.createElement('p');
  filtersElement.textContent = `Filters: ${filters}`;
  metadataContainer.appendChild(filtersElement);

  const dateElement = document.createElement('p');
  dateElement.textContent = `Date: ${downloadDate}`;
  metadataContainer.appendChild(dateElement);

  const websiteElement = document.createElement('p');
  websiteElement.textContent = `Downloaded from ${websiteName}`;
  metadataContainer.appendChild(websiteElement);

  // Append metadata to the chartNode
  chartNode.appendChild(metadataContainer);

  try {
    // Capture the chart with metadata as a PNG
    const dataUrl = await toPng(chartNode);
    const link = document.createElement('a');
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  } catch (error) {
    console.error('Failed to download chart as PNG', error);
  } finally {
    // Clean up: Remove the metadata container
    chartNode.removeChild(metadataContainer);
  }
};
