export const formatFilters = (filters) => {
    if (!filters) {
      return 'No filters applied';
    }

    const filterEntries = [];

    if (filters.category) {
      filterEntries.push(`Category: ${filters.category}`);
    }
    if (filters.platform) {
      filterEntries.push(`Platform: ${filters.platform}`);
    }
    if (filters.sentiment) {
      filterEntries.push(`Sentiment: ${filters.sentiment}`);
    }
    if (filters.subcategories && filters.subcategories.length > 0) {
      filterEntries.push(`Subcategories: ${filters.subcategories.join(', ')}`);
    }
    if (filters.regions && filters.regions.length > 0) {
      filterEntries.push(`Regions: ${filters.regions.join(', ')}`);
    }
    if (filters.aspects && filters.aspects.length > 0) {
      filterEntries.push(`Aspects: ${filters.aspects.join(', ')}`);
    }

    return filterEntries.length > 0 ? filterEntries.join(' | ') : 'No filters applied';
};
