import React from 'react';
import { Link } from 'react-router-dom';
import './about.css';

import { GoGlobe } from 'react-icons/go';
import { GoDatabase } from 'react-icons/go';
import { LuBrain } from 'react-icons/lu';
import { FaRegMap } from 'react-icons/fa';
import { FaArrowRight } from 'react-icons/fa6';

const About = () => {
  const featureCards = [
    {
      icon: <GoGlobe color="#fff" size={48} />,
      title: 'Real-Time Tourism Insights',
      description:
        'Capture live data on tourist sentiments, destinations, and experiences across Malawi.',
    },
    {
      icon: <GoDatabase color="#fff" size={48} />,
      title: 'Advanced Data Analysis',
      description:
        'Transform raw data into actionable insights for tourism businesses and policymakers.',
    },
    {
      icon: <LuBrain color="#fff" size={48} />,
      title: 'AI-Powered Predictions',
      description:
        'Leverage AI and NLP to forecast tourism trends and visitor preferences.',
    },
    {
      icon: <FaRegMap color="#fff" size={48} />,
      title: 'Geospatial Understanding',
      description:
        'Utilize Geographic Information Systems to map and analyze tourism patterns.',
    },
  ];

  return (
    <div className="mlondola-about-page">
      <div className="container">
        <header className="hero-section">
          <h1>Welcome To Mlondola!</h1>
          <p>
            Enhancing Malawi's Tourism Sector Through Real-Time Data Insights
          </p>
          <div className="hero-cta">
            <Link to="/home" className="explore-button">
              Explore Mlondola
              <FaArrowRight size={20} className="button-icon" />
            </Link>
          </div>
        </header>

        <section className="about-section">
          <h2>About Mlondola</h2>
          <div className="about-content">
            <p>
              Mlondola is a joint initiative between UNDP Malawi Accelerator
              Lab, ICPSD SDG AI Lab and local partners, designed to enhance the
              tourism sector through real-time data insights in Malawi.
              Traditional reports, like the annual International Visitors' Exit
              Survey, leave a gap in timely information. To bridge this,
              Mlondola focuses on data analysis and interactive visualizations
              to provide actionable insights.
            </p>
            <p>
              The platform gathers real-time data from various sources to
              capture tourist sentiments, popular destinations, preferred
              accommodations, and activities. By leveraging advanced
              technologies such as Artificial Intelligence (AI), Natural
              Language Processing (NLP), and Geographic Information Systems
              (GIS), tourism businesses in Malawi can better understand visitor
              preferences, improve services, and boost customer satisfaction.
            </p>
            <p>
              Predictive analytics further forecast future trends based on
              historical data, helping stakeholders anticipate demand and make
              informed decisions. This innovative, open-source approach supports
              Malawi's tourism growth and sets a model for smarter, more
              responsive tourism management worldwide.
            </p>
          </div>
        </section>

        <section className="technologies-section">
          <h2>Key Technologies</h2>
          <div className="technologies-grid">
            {featureCards.map((card, index) => (
              <div key={index} className="technology-card">
                <div className="technology-icon">{card.icon}</div>
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="partners-section">
          <h2>Our Partners</h2>
          {/* <div className="partners-logos">
            <div className="partner-logo">UNDP Malawi</div>
            <div className="partner-logo">ICPSD SDG AI Lab</div>
            <div className="partner-logo">Local Partners</div>
          </div> */}
          <div className="partners-logos">
            <img
              className="home-sponsors-image"
              src={require('./../../assets/visitmalawilogo.png')}
            />
            <img
              className="home-sponsors-image"
              src={require('./../../assets/Coat_of_arms_of_Malawi.svg.png')}
            />
            <img
              className="home-sponsors-image"
              src={require('./../../assets/sdgailab_logo.png')}
            />
            <img
              className="home-sponsors-image"
              src={require('./../../assets/undp_logo.png')}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
