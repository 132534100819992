import React, { useContext } from 'react';
import Context from '../../context/context';
import { ASPECT_TYPES } from '../../constants';
import { FaDownload } from 'react-icons/fa';
import * as XLSX from 'xlsx';

const DownloadCategoriesDataButton = () => {
  const { filteredReviews } = useContext(Context);

  const computeAspectsData = () => {
    const aspectsDataMap = {};
    ASPECT_TYPES.forEach((aspect) => {
      aspectsDataMap[aspect] = 0;
    });

    filteredReviews.forEach((review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        aspectsDataMap[aspect.aspect] += 1;
      });
    });

    return Object.keys(aspectsDataMap).map((aspect) => ({
      Aspect: aspect,
      Count: aspectsDataMap[aspect],
    }));
  };

  const computeSentimentTimeData = () => {
    let sentimentArrays = filteredReviews.reduce(
      (sentimentAcc, review) => {
        const formatDate = new Date(review.date).toISOString().split('T')[0];
        review.aspectsentiment_set.forEach((aspect) => {
          if (aspect.label === 'Positive') {
            sentimentAcc.positive[formatDate]
              ? (sentimentAcc.positive[formatDate] += 1)
              : (sentimentAcc.positive[formatDate] = 1);
          } else {
            sentimentAcc.negative[formatDate]
              ? (sentimentAcc.negative[formatDate] += 1)
              : (sentimentAcc.negative[formatDate] = 1);
          }
        });
        return sentimentAcc;
      },
      { positive: {}, negative: {} }
    );

    const sentimentArrayMerged = [];
    Object.keys(sentimentArrays.positive).forEach((date) => {
      sentimentArrayMerged.push({
        Date: new Date(date).toISOString().split('T')[0],
        Type: 'Positive',
        Value: sentimentArrays.positive[date],
      });
    });

    Object.keys(sentimentArrays.negative).forEach((date) => {
      sentimentArrayMerged.push({
        Date: new Date(date).toISOString().split('T')[0],
        Type: 'Negative',
        Value: sentimentArrays.negative[date],
      });
    });

    return sentimentArrayMerged;
  };

  const computeVisitsOverYears = () => {
    const yearMap = {};
    filteredReviews.forEach((review) => {
      const date = new Date(review.date);
      const year = date.getFullYear();
      if (!yearMap[year]) {
        yearMap[year] = { visits: 0, positive: 0, negative: 0 };
      }
      yearMap[year].visits += 1;
      if (
        review.aspectsentiment_set.some((aspect) => aspect.label === 'Positive')
      ) {
        yearMap[year].positive += 1;
      }
      if (
        review.aspectsentiment_set.some((aspect) => aspect.label === 'Negative')
      ) {
        yearMap[year].negative += 1;
      }
    });

    return Object.entries(yearMap).map(([year, data]) => ({
      Year: year,
      Reviews: data.visits,
      Positive: data.positive,
      Negative: data.negative,
    }));
  };

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();

    const visitsData = computeVisitsOverYears();
    const visitsSheet = XLSX.utils.json_to_sheet(visitsData);
    XLSX.utils.book_append_sheet(workbook, visitsSheet, 'Reviews Over Years');

    const sentimentData = computeSentimentTimeData();
    const sentimentSheet = XLSX.utils.json_to_sheet(sentimentData);
    XLSX.utils.book_append_sheet(
      workbook,
      sentimentSheet,
      'Sentiments Over Time'
    );

    const aspectsData = computeAspectsData();
    const aspectsSheet = XLSX.utils.json_to_sheet(aspectsData);
    XLSX.utils.book_append_sheet(
      workbook,
      aspectsSheet,
      'Aspects Distribution'
    );

    XLSX.writeFile(workbook, 'Categories_Data.xlsx');
  };

  return (
    <button
      onClick={handleDownloadExcel}
      className="button-main"
      style={{ height: '38px' }}
    >
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadCategoriesDataButton;
