import React from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import './chart-card.css';

const ChartCard = (props) => {
  const { title, children, titleSmall } = props;
  return (
    <>
      {
        title && (
          <h3 className={`block-header ${titleSmall ? 'title-small' : ''}`}>{title}</h3>
        )
      }
      <div className='chart-card-container'>
        { children }
      </div>
    </>
  )
}

ChartCard.propTypes = {};

ChartCard.defaultProps = {};

export default ChartCard;
