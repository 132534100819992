import React, { useState, useContext, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Context from '../../context/context';
import { ASPECT_TYPES } from '../../constants';
import { FaDownload } from 'react-icons/fa';

const DownloadRegionDataButton = () => {
  const { filteredReviews } = useContext(Context);
  const [regionData, setRegionData] = useState({});
  const [establishmentSentimentData, setEstablishmentSentimentData] = useState(
    []
  );
  const [aspectSentimentData, setAspectSentimentData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);

  useEffect(() => {
    if (filteredReviews.length > 0) {
      setRegionData(computeRegionData());
      setEstablishmentSentimentData(getEstablishmentSentimentData());
      setAspectSentimentData(getAspectSentimentData());
      setKeywordData(computeKeywordFrequency());
    }
  }, [filteredReviews]);

  const computeRegionData = () => {
    const visitsData = filteredReviews.reduce((acc, review) => {
      let region = review.district || 'Unknown';
      acc[region] = (acc[region] || 0) + 1;
      return acc;
    }, {});

    const sentimentData = filteredReviews.reduce((acc, review) => {
      let region = review.district || 'Unknown';
      if (!acc[region]) {
        acc[region] = { negative: 0, positive: 0 };
      }
      review.aspectsentiment_set.forEach((aspect) => {
        if (aspect.label === 'Negative') {
          acc[region].negative += 1;
        } else {
          acc[region].positive += 1;
        }
      });
      return acc;
    }, {});

    return { visits: visitsData, sentiment: sentimentData };
  };

  const getEstablishmentSentimentData = () => {
    const EstablishmentMap = {};

    filteredReviews.forEach((review) => {
      if (!EstablishmentMap[review.establishment_type]) {
        EstablishmentMap[review.establishment_type] = {
          positive: 0,
          negative: 0,
        };
      }
      if (review.aspectsentiment_set.length > 0) {
        if (
          review.aspectsentiment_set.some(
            (aspect) => aspect.label === 'Positive'
          )
        ) {
          EstablishmentMap[review.establishment_type].positive += 1;
        }
        if (
          review.aspectsentiment_set.some(
            (aspect) => aspect.label === 'Negative'
          )
        ) {
          EstablishmentMap[review.establishment_type].negative += 1;
        }
      }
    });

    const res = Object.entries(EstablishmentMap).map(
      ([establishment, establishmentData]) => ({
        Type: establishment,
        'Positive Sentiments': establishmentData.positive,
        'Negative Sentiments': establishmentData.negative,
      })
    );

    res.columns = ['Type', 'Positive Sentiments', 'Negative Sentiments'];

    return res;
  };

  const getAspectSentimentData = () => {
    const aspectMap = filteredReviews.reduce((acc, review) => {
      review.aspectsentiment_set.forEach((aspect) => {
        if (!acc[aspect.aspect]) {
          acc[aspect.aspect] = { positive: 0, negative: 0 };
        }
        if (aspect.label === 'Positive') {
          acc[aspect.aspect].positive += 1;
        } else {
          acc[aspect.aspect].negative += 1;
        }
      });
      return acc;
    }, {});

    return Object.entries(aspectMap).map(([aspect, data]) => ({
      Aspect: aspect,
      Positive: data.positive,
      Negative: data.negative,
    }));
  };

  const computeKeywordFrequency = () => {
    const keywordFrequency = filteredReviews.reduce((acc, review) => {
      review.keyword_set.forEach((keywordObj) => {
        const keywords = keywordObj.keywords.split(', ');
        keywords.forEach((keyword) => {
          const cleanKeyword = keyword.replace(/[\[\]',]/g, '').trim();
          acc[cleanKeyword] = (acc[cleanKeyword] || 0) + 1;
        });
      });
      return acc;
    }, {});

    return Object.entries(keywordFrequency).map(([keyword, count]) => ({
      Keyword: keyword,
      Frequency: count,
    }));
  };

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();

    const regionVisitsSheet = XLSX.utils.json_to_sheet(
      Object.keys(regionData.visits).map((region) => ({
        Region: region,
        Reviews: regionData.visits[region] || 0,
      }))
    );

    const sentimentSheet = XLSX.utils.json_to_sheet(
      Object.keys(regionData.sentiment).map((region) => ({
        Region: region,
        Positive: regionData.sentiment[region]?.positive || 0,
        Negative: regionData.sentiment[region]?.negative || 0,
      }))
    );

    const establishmentSentimentSheet = XLSX.utils.json_to_sheet(
      establishmentSentimentData
    );

    const aspectSentimentSheet = XLSX.utils.json_to_sheet(aspectSentimentData);

    const keywordFrequencySheet = XLSX.utils.json_to_sheet(keywordData);

    XLSX.utils.book_append_sheet(workbook, regionVisitsSheet, 'Region Reviews');
    XLSX.utils.book_append_sheet(
      workbook,
      sentimentSheet,
      'Sentiment Distribution'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      establishmentSentimentSheet,
      'Establishment Sentiments'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      aspectSentimentSheet,
      'Aspect Sentiments'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      keywordFrequencySheet,
      'Keyword Frequency'
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'region_data.xlsx';
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={handleDownloadExcel}
      className="button-main"
      style={{ height: '38px' }}
    >
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadRegionDataButton;
