import React from 'react';
import { downloadChartAsPNG } from '../../utils/download-chart';
import { FaImage } from 'react-icons/fa6';
import './download-as-png.css';

const DownloadAsPNGButton = (props) => {
  const { chartId, fileName = 'chart.png', metadata = {} } = props;

  const handleDownload = () => {
    downloadChartAsPNG(chartId, fileName, metadata);
  };

  return (
    <button
      title="Download as PNG"
      onClick={handleDownload}
      className="download-btn"
    >
      <FaImage color="white" />
    </button>
  );
};

export default DownloadAsPNGButton;
