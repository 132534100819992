import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Space } from 'antd';
import Counter from '../counter/counter.js';
import ChartTooltip from '../chart-tooltip/chart-tooltip.js';
import './stat-info-chart.css';
import * as d3 from 'd3';

const StatInfoChart = (props) => {
  const { title, value, dimension, id, className } = props;
  const [tooltipTop, setTooltipTop] = useState('auto');
  const [tooltipLeft, setTooltipLeft] = useState('auto');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [tooltipValue, setTooltipValue] = useState('');
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const bisect = d3.bisector(d => d.date).left;

  function computeChartData(dataToProcess) {
    const dateMap = dataToProcess.reduce((reviewAcc, review)=>{
      if(reviewAcc[review.travel_date]) {
        reviewAcc[review.travel_date] +=1
      } else {
        reviewAcc[review.travel_date] = 1
      }
      return reviewAcc
    }, {})
    return Object.keys(dateMap).map(dateKey => {
      return {
        date: new Date(dateKey),
        value: dateMap[dateKey]
      }
    }).sort((a,b) => {
      return a.date - b.date;
    })
  }

  function initChart() {
    const initialwidth = document.getElementById(`chart-${id}`).getBoundingClientRect().width,
    initialHeight = initialwidth > 160 ? 130 : initialwidth,
    svg = d3.select(`#chart-${id}`)
      .append('svg')
        .attr('width', initialwidth)
        .attr('height', initialHeight)
        .append('g')
        .attr('transform',
              'translate(0,0)'); 

    const createGradient = select => {
      const gradient = select
        .select('defs')
          .append('linearGradient')
            .attr('id', 'gradient')
            .attr('x1', '0%')
            .attr('y1', '100%')
            .attr('x2', '0%')
            .attr('y2', '0%');

      gradient
        .append('stop')
          .attr('offset', '0%')
          .attr('style', 'stop-color:#A78EF7;stop-opacity:0');

      gradient
        .append('stop')
          .attr('offset', '100%')
          .attr('style', 'stop-color:#FDB022;stop-opacity:1');
    }

    const createCircleGradient = select => {
      select
        .select('defs')
        .append('radialGradient')
        .attr('id', 'chart-highlight-gradient')
        .selectAll('stop')
        .data([
          {offset: '0%', color:  '#A78EF700'},
          {offset: '100%', color: '#FDB022'}
        ])
        .enter().append('stop')
        .attr('offset', function(d) { return d.offset; })
        .attr('stop-color', function(d) { return d.color; });
    }
    setWidth(initialwidth);
    setHeight(initialHeight);
    svg.append('defs');
    svg.call(createGradient);
    svg.call(createCircleGradient);
  }

  function updateChart() {
    const svg = d3.select(`#chart-${id} svg g`);

    const x = d3.scaleTime()
      .domain(d3.extent(data, d => d.date))
      .range([ 0, width ]);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => +d.value)])
      .range([ height, 0 ]);

    const line = svg.selectAll('.line')
      .data([data], function(d){ return d.date });

    const fill = svg.selectAll('.fill')
        .data([data], function(d){ return d.date });

    line
      .join("path")
      .attr("class","line")
      .attr('fill', 'none')
      .attr('stroke', '#FDB022')
      .transition()
      .duration(1000)
      .attr('stroke-width', 2)
      .attr('d', d3.line()
        .x(function(d) { return x(d.date) })
        .y(function(d) { return y(d.value) })
      )

    fill
      .join("path")
      .attr("class","fill")
      .attr('fill', 'url(#gradient)')
      .transition()
      .duration(1000)
      .attr('d', d3.area()
        .x(d => x(d.date))
        .y0(y(0))
        .y1(d => y(d.value))
      )

    const focus = svg
      .append('g')
      .append('circle')
      .attr('fill', 'url(#chart-highlight-gradient)')
      .attr('stroke', '#FDB022')
      .attr('r', 8.5)
      .style('opacity', 0)

    function mouseover() {
      focus.style('opacity', 1)
      setTooltipVisible(true)
    }

    function mousemove(e) {
      let x0 = x.invert(d3.pointer(e, svg.node())[0]),
      i = bisect(data, x0),
      selectedData = data[i >= data.length ? data.length-1 : i];
      setTooltipTitle(selectedData.date.getDate() + '/' + selectedData.date.getMonth() + '/' + selectedData.date.getFullYear());
      setTooltipValue(selectedData.value);
      focus
        .attr('cx', x(selectedData.date))
        .attr('cy', y(selectedData.value))
      let tooltipXposition = x(selectedData.date),
      tooltipYposition = y(selectedData.value);
      setTooltipTop(tooltipYposition +'px')
      setTooltipLeft(tooltipXposition +'px')
    }
    
    function mouseout(d) {
      if(d.toElement && typeof d.toElement.className === 'string' && d.toElement.className.includes('chart-tooltip')) return
      focus.style('opacity', 0)
      setTooltipVisible(false)
    }

    svg
      .append('rect')
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseout', mouseout);
  }

  useEffect(()=> {
    setData(computeChartData(value));
  },[value]);

  useEffect(updateChart, [data]);
  useEffect(initChart, []);

  return (
    <Card className={className} classNames={{body:'full-height'}} bordered={false}>
      <Space
        direction='vertical'
        size='middle'
        style={{ display: 'flex', height: '100%', justifyContent: 'space-between' }}
      >
        <Counter
          title={title}
          value={value.length}
          dimension={dimension}
        />
        <div className='stat-chart-container' id={`chart-${id}`}>
          <ChartTooltip
            top={tooltipTop}
            left={tooltipLeft}
            title={tooltipTitle}
            visible={tooltipVisible}
            value={tooltipValue}
            dimension={dimension ? dimension : ''}
          />
        </div>
      </Space>
    </Card>
  )
}

StatInfoChart.propTypes = {};

StatInfoChart.defaultProps = {};

export default StatInfoChart;
