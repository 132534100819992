import React, { useContext } from 'react';
import { FaDownload } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import Context from '../../context/context';

const DownloadStatisticalDataButton = () => {
  const {
    filteredReviews,
    filteredEstablishments,
    selectedCategory,
    selectedRegion,
    selectedSentiment,
    selectedAspect,
  } = useContext(Context);

  const computeVisitsOverTime = () => {
    const visitsByDate = filteredReviews.reduce((acc, review) => {
      const date = new Date(review.date).toISOString().split('T')[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});
    return Object.keys(visitsByDate).map((date) => ({
      Date: date,
      Reviews: visitsByDate[date],
    }));
  };

  const computeVisitsByRegion = () => {
    const data = filteredReviews
      .filter((r) => r.district)
      .reduce((reviewAcc, review) => {
        if (reviewAcc[review.district]) {
          reviewAcc[review.district] += 1;
        } else {
          reviewAcc[review.district] = 1;
        }
        return reviewAcc;
      }, {});

    return Object.keys(data).map((region) => {
      return {
        Region: region,
        'Number of Reviews': data[region],
      };
    });
  };

  const computeTypesDistribution = () => {
    const typesByCategory = filteredEstablishments.reduce(
      (acc, establishment) => {
        const type = establishment.subcategory || 'Unknown';
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      },
      {}
    );
    return Object.keys(typesByCategory).map((type) => ({
      Type: type,
      Count: typesByCategory[type],
    }));
  };

  const computeVisitsBySeason = () => {
    const seasonKeyList = [];
    const seasonVisitMap = {};

    const datelist = [...filteredReviews].sort((a, b) => {
      return a.date - b.date;
    });

    datelist.forEach((review) => {
      const date = new Date(review.date);
      const month = date.getMonth();
      let season;

      if (month < 2 || month === 11) {
        season = 'Winter';
      } else if (month < 5) {
        season = 'Spring';
      } else if (month < 8) {
        season = 'Summer';
      } else {
        season = 'Autumn';
      }
      let seasonKey = `${season} ${date.getFullYear()}`;
      if (season === 'Winter') {
        if (month < 2) {
          seasonKey = `${season} ${date.getFullYear()}`;
        } else {
          seasonKey = `${season} ${date.getFullYear() - 1}`;
        }
      }
      if (!seasonKeyList.includes(seasonKey)) {
        seasonKeyList.push(seasonKey);
      }
      seasonVisitMap[seasonKey]
        ? (seasonVisitMap[seasonKey] += 1)
        : (seasonVisitMap[seasonKey] = 1);
    });
    return seasonKeyList.map((seasonKey) => {
      return {
        Season: seasonKey,
        'Number of Reviews': seasonVisitMap[seasonKey],
      };
    });
  };

  const computeGenderDistribution = () => {
    const totalReviews = filteredReviews.length;

    const genderDistribution = filteredReviews.reduce((acc, review) => {
      const gender = review.reviewer_gender_prediction || 'Unknown';
      acc[gender] = (acc[gender] || 0) + 1;
      return acc;
    }, {});

    return Object.keys(genderDistribution).map((gender) => ({
      Gender: gender,
      Count: genderDistribution[gender],
      Percentage: ((genderDistribution[gender] / totalReviews) * 100).toFixed(
        1
      ),
    }));
  };

  const computeVisitsByOrigin = () => {
    const visitsByOrigin = filteredReviews.reduce((acc, review) => {
      const origin = review.location || 'Unknown';
      acc[origin] = (acc[origin] || 0) + 1;
      return acc;
    }, {});
    return Object.keys(visitsByOrigin).map((origin) => ({
      Country: origin,
      Reviews: visitsByOrigin[origin],
    }));
  };

  const handleDownloadExcel = () => {
    const workbook = XLSX.utils.book_new();

    const visitsOverTimeSheet = XLSX.utils.json_to_sheet(
      computeVisitsOverTime()
    );
    const visitsByRegionSheet = XLSX.utils.json_to_sheet(
      computeVisitsByRegion()
    );
    const typesDistributionSheet = XLSX.utils.json_to_sheet(
      computeTypesDistribution()
    );
    const visitsBySeasonSheet = XLSX.utils.json_to_sheet(
      computeVisitsBySeason()
    );
    const genderDistributionSheet = XLSX.utils.json_to_sheet(
      computeGenderDistribution()
    );
    const visitsByOriginSheet = XLSX.utils.json_to_sheet(
      computeVisitsByOrigin()
    );

    XLSX.utils.book_append_sheet(
      workbook,
      visitsOverTimeSheet,
      'Reviews Over Time'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      visitsByRegionSheet,
      'Reviews By Region'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      typesDistributionSheet,
      'Types Distribution'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      visitsBySeasonSheet,
      'Reviews By Season'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      genderDistributionSheet,
      'Gender Distribution'
    );
    XLSX.utils.book_append_sheet(
      workbook,
      visitsByOriginSheet,
      'Reviews By Origin'
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'statistical_info.xlsx';
    link.click();

    URL.revokeObjectURL(url);
  };

  return (
    <button
      onClick={handleDownloadExcel}
      className="button-main"
      style={{ height: '38px' }}
    >
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadStatisticalDataButton;
