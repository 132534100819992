import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'antd';
import './summary.css';

const summaryItems = [
  {
    icon: 'new-reviews',
    title: 'New Reviews',
    value: 150,
  },
  {
    icon: 'positive-reviews',
    title: 'Positive Reviews',
    value: 200,
  },
  {
    icon: 'bad-reviews',
    title: 'Negative Reviews',
    value: 413,
  },
  {
    icon: 'open-reviews',
    title: 'Aspects Mentioned',
    value: 150,
  }
]

const SummaryCounter = (props) => {
  const { icon, title, value } = props;
  return (
    <div className="summary-counter" data-testid="SummaryCounter">
      <i className="summary-counter_icon">
        <img
          className="summary-counter_icon-image"
          src={require(`./../../assets/icons/${icon}.svg`)}      />
      </i>
      <div className='summary-counter_data'>
        <h3 className="summary-counter_title">
          {title}
        </h3>
        <p className="summary-counter_value">
          {value}
        </p>
      </div>
    </div>
  )
}

const Summary = () => (
  <div className="summary" data-testid="Summary">
    <Card bordered={false}>
      <Row>
        {
          summaryItems.map((item, index) => {
            return (
              <Col key={item.icon} className='d-flex' span={6}>
                <SummaryCounter
                  icon={item.icon}
                  title={item.title}
                  value={item.value}
                />
                { index !== summaryItems.length-1 ? <div className='summary-spacer'></div> : null }
              </Col>
            )
          })
        }
      </Row>
    </Card>
  </div>
);

Summary.propTypes = {};

Summary.defaultProps = {};

export default Summary;
