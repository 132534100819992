import React, { useContext, useState, useEffect } from 'react';
import Context from '../../context/context';
import * as XLSX from 'xlsx';
import { ASPECT_TYPES } from '../../constants';
import { FaDownload } from 'react-icons/fa';

const DownloadPricesDataButton = () => {
  const { filteredEstablishments, selectedCategory, updateSelectedCategory } =
    useContext(Context);
  const computePricesOverTime = () => {
    const priceMap = filteredEstablishments.reduce(
      (priceAcc, establishment) => {
        if (establishment.price_range_avg) {
          if (priceAcc[establishment.time_of_scrape_iso]) {
            priceAcc[establishment.time_of_scrape_iso].push(
              establishment.price_range_avg
            );
          } else {
            priceAcc[establishment.time_of_scrape_iso] = [
              establishment.price_range_avg,
            ];
          }
        }
        return priceAcc;
      },
      {}
    );

    return Object.keys(priceMap)
      .map((dateKey) => {
        const prices = priceMap[dateKey];
        const averagePrice =
          prices.reduce((acc, price) => acc + price, 0) / prices.length;
        return {
          date: new Date(dateKey),
          value: averagePrice,
        };
      })
      .sort((a, b) => a.date - b.date);
  };

  const [prices, setPrices] = useState([]);

  useEffect(() => {
    setPrices(computePricesOverTime());
  }, [filteredEstablishments]);

  const downloadPricesData = () => {
    const ws = XLSX.utils.json_to_sheet(prices);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Prices over time');
    XLSX.writeFile(wb, 'prices-over-time.xlsx');
  };

  return (
    <button
      onClick={downloadPricesData}
      className="button-main"
      style={{ height: '38px' }}
    >
      <FaDownload color="white" />
    </button>
  );
};

export default DownloadPricesDataButton;
