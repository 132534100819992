import React, { useContext } from 'react';
import Context from '../../context/context';
import { FaTimes } from 'react-icons/fa';

import './applied-filter.css';

const AppliedFilters = () => {
  const {
    selectedPlatform,
    selectedCategory,
    selectedSubCategory,
    selectedSentiment,
    selectedAspect,
    selectedRegion,
    updateSelectedPlatform,
    updateSelectedCategory,
    updateSelectedSubCategory,
    updateSelectedSentiment,
    updateSelectedAspect,
    updateSelectedRegion,
  } = useContext(Context);

  const removeFilter = (filterType, filterValue) => {
    switch (filterType) {
      case 'Platform':
        updateSelectedPlatform(null);
        break;
      case 'Category':
        updateSelectedCategory(null);
        break;
      case 'Subcategory':
        updateSelectedSubCategory([]);
        break;
      case 'Sentiment':
        updateSelectedSentiment(null);
        break;
      case 'Aspect':
        updateSelectedAspect([]);
        break;
      case 'Region':
        updateSelectedRegion([]);
        break;
      default:
        break;
    }
  };

  const renderAppliedFilters = () => {
    const filters = [];

    if (selectedPlatform) {
      filters.push({
        label: `Platform: ${selectedPlatform}`,
        type: 'Platform',
        value: selectedPlatform,
      });
    }
    if (selectedCategory) {
      filters.push({
        label: `Category: ${selectedCategory}`,
        type: 'Category',
        value: selectedCategory,
      });
    }
    if (selectedSubCategory.length > 0) {
      filters.push({
        label: `Subcategories: ${selectedSubCategory.join(', ')}`,
        type: 'Subcategory',
        value: selectedSubCategory,
      });
    }
    if (selectedSentiment) {
      filters.push({
        label: `Sentiment: ${selectedSentiment}`,
        type: 'Sentiment',
        value: selectedSentiment,
      });
    }
    if (selectedAspect.length > 0) {
      filters.push({
        label: `Aspects: ${selectedAspect.join(', ')}`,
        type: 'Aspect',
        value: selectedAspect,
      });
    }
    if (selectedRegion.length > 0) {
      filters.push({
        label: `Regions: ${selectedRegion.join(', ')}`,
        type: 'Region',
        value: selectedRegion,
      });
    }

    return filters.length > 0 ? (
      <div className="applied-filters">
        <h4>Applied Filters:</h4>
        <div className="filters-list">
          {filters.map((filter, index) => (
            <span key={index} className="filter-item">
              <div>{filter.label}</div>
              <button
                title={`Remove ${filter.label}`}
                className="remove-filter"
              >
                <FaTimes
                  color="white"
                  size={11}
                  onClick={() => removeFilter(filter.type, filter.value)}
                />
              </button>
              {index < filters.length - 1 && (
                <span className="filter-separator"> | </span>
              )}
            </span>
          ))}
        </div>
      </div>
    ) : null;
  };

  return renderAppliedFilters();
};

export default AppliedFilters;
