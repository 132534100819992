 const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FILTERED_ESTABLISHMENTS':
      return { ...state, filteredEstablishments: action.payload };
    case 'UPDATE_FILTERED_REVIEWS':
      return { ...state, filteredReviews: action.payload };
    case 'UPDATE_NO_PLATFORM_FILTERED_REVIEWS':
      return { ...state, noPlatformFilteredReviews: action.payload };
    case 'UPDATE_ACTIVE_SUB_CATEGORIES':
      return { ...state, activeSubCategories: action.payload };
    case 'UPDATE_SELECTED_PLATFORM':
      return { ...state, selectedPlatform: action.payload };
    case 'UPDATE_SELECTED_CATEGORY':
      return { ...state, selectedCategory: action.payload };
    case 'UPDATE_SELECTED_SUB_CATEGORY':
      return { ...state, selectedSubCategory: action.payload };
    case 'UPDATE_SELECTED_DATES':
      return { ...state, selectedDates: action.payload };
    case 'UPDATE_SELECTED_SENTIMENT':
      return { ...state, selectedSentiment: action.payload };
    case 'UPDATE_SELECTED_ASPECT':
      return { ...state, selectedAspect: action.payload };
    case 'UPDATE_SELECTED_REGION':
      return { ...state, selectedRegion: action.payload };
    default:
      return { ...state };
  }
};
export default reducer;